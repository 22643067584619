import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PartnerCard from "../components/partner-card"

const Customers = ({ data }) => (
	<Layout>
		<SEO title="Customers - Industry leaders trusting Celantur" description="Read more about our trusted partners." />
		<section className="pt-8 pt-md-11">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-md-10 col-lg-8 text-center mb-8">
						<h1 className="display-3 font-weight-bold">Our Customers</h1>
					</div>
				</div>
				<div className="row">
					<PartnerCard
						name="STRABAG AG"
						color="#d72622"
						image={data.logoStrabag.childImageSharp.fluid}
						text="One of Europe's largest construction companies"
						link="https://www.strabag.com/"
						city="Vienna"
						country="Austria"
					/>
					<PartnerCard
						name="NavVis"
						color="#1979e4"
						image={data.logoNavVis.childImageSharp.fluid}
						text="A leading global technology provider of spatial intelligence"
						link="https://www.navvis.com/"
						city="Munich"
						country="Germany"
					/>
					<PartnerCard
						name="Esri"
						color="#000000"
						image={data.logoEsri.childImageSharp.fluid}
						text="Esri is the global market leader in geographic information system (GIS) software, location intelligence, and mapping."
						link="https://www.esri.com/"
						city="Redlands"
						country="USA"
					/>
					<PartnerCard
						name="iNovitas AG"
						color="#1979e4"
						image={data.logoInovitas.childImageSharp.fluid}
						text="A highly specialized service provider for the digitalization and cloud-based use of detailed and highly accurate 3D-imagery of streets and railways."
						link="https://www.inovitas.ch/"
						city="Baden-Dättwil"
						country="Switzerland"
					/>
					<PartnerCard
						name="STF Gruppe"
						color="#303f52"
						image={data.logoSTFGruppe.childImageSharp.fluid}
						text="360° engineering competence"
						link="https://www.stf-gruppe.de/"
						city="Dülmen"
						country="Germany"
					/>
					<PartnerCard
						name="AllTerra DS"
						color="#015B9A"
						image={data.logoAllTerraDS.childImageSharp.fluid}
						text="Trimble competence center for geospatial solutions (Southern Germany)"
						link="https://allterra-ds.de/"
						city="Dettelbach"
						country="Germany"
					/>
					<PartnerCard
						name="InfraDigital AG"
						color="#4FF496"
						image={data.logoInfraDigital.childImageSharp.fluid}
						text="Enabler for digital construction"
						link="https://infradigital.ch/"
						city="Landquart"
						country="Switzerland"
					/>
					<PartnerCard
						name="GPS Lands (M) Sdn. Bhd."
						color="#F9AD18"
						image={data.logoGpsLands.childImageSharp.fluid}
						text="Leading Geospatial and Mapping Technologies solution provider in the ASEAN region"
						link="https://www.gpslands.com.my/"
						city="Kuala Lumpur"
						country="Malaysia"
					/>
					<PartnerCard
						name="CamenAI B.V."
						color="#36E0A1"
						image={data.logoCamenAI.childImageSharp.fluid}
						text="Manage and optimise your public space assets with AI"
						link="https://www.camenai.com/"
						city="Utrecht"
						country="Netherlands"
					/>
					<PartnerCard
						name="RedPlanet Bhd."
						color="#E10707"
						image={data.logoRedPlanet.childImageSharp.fluid}
						text="Global premier GIS and ICT service provider"
						link="https://redplanet.com.my/"
						city="Kuala Lumpur"
						country="Malaysia"
					/>
					<PartnerCard
						name="galileo-ip Ingenieure GmbH"
						color="#EF8421"
						image={data.logoGalileoip.childImageSharp.fluid}
						text="Surveyor's office in Northern Bavaria"
						link="https://www.galileo-ip.de/"
						city="Altenstadt"
						country="Germany"
					/>
					<PartnerCard
						name="CON+SCAN TECH, IFSB GmbH"
						color="#000000"
						image={data.logoConScanTech.childImageSharp.fluid}
						text="Digital Engineering &amp; Smart Construction"
						link="https://ifsb.eu/"
						city="Dortmund"
						country="Germany"
					/>
					<PartnerCard
						name="AllTerra Hungary Kft."
						color="#015B9A"
						image={data.logoAllTerraHungary.childImageSharp.fluid}
						text="Trimble competence center for geospatial solutions (Hungary)"
						link="https://allterra-hungary.com/"
						city="Budapest"
						country="Hungary"
					/>
					<PartnerCard
						name="orbis360 SA"
						color="#00233F"
						image={data.logoOrbis360.childImageSharp.fluid}
						text="3D Mobile Mapping, GIS"
						link="https://orbis360.ch/"
						city="Sion"
						country="Switzerland"
					/>
					<PartnerCard
						name="Comcentric"
						color="#ce4c9c"
						image={data.logoComcentric.childImageSharp.fluid}
						text="Intermodal Transport Systems"
						link="https://comcentric.com.my/"
						city="Kuala Lumpur"
						country="Malaysia"
					/>
					<PartnerCard
						name="3D Target srl"
						color="#e11e24"
						image={data.logo3DTarget.childImageSharp.fluid}
						text="Mobile mapping data analysis software"
						link="http://www.3dtarget.it/landing/en/"
						city="Brescia"
						country="Italy"
					/>
					<PartnerCard
						name="qapture GmbH"
						color="#69c9ec"
						image={data.logoQapture.childImageSharp.fluid}
						text="Virtualize Reality"
						link="http://www.3dtarget.it/landing/en/"
						city="Linz"
						country="Austria"
					/>
					<PartnerCard
						name="AllTerra Österreich"
						color="#015B9A"
						image={data.logoAllTerraDS.childImageSharp.fluid}
						text="Trimble competence center for geospatial solutions (Austria)"
						link="https://www.allterra-oesterreich.at/"
						city="Steyr"
						country="Austria"
					/>
					<PartnerCard
						name="Star Vision Ltd."
						color="#B5FFFE"
						image={data.logoStarVision.childImageSharp.fluid}
						text="System integrator, geoinformatics and IT consultancy"
						link="http://www.starvision.com.hk/"
						city="Hong Kong"
						country="China"
					/>
					<PartnerCard
						name="Horus View and Explore B.V."
						color="#1Cb8E8"
						image={data.logoHorus.childImageSharp.fluid}
						text="Video and Sensor Technology, Geographical Information Systems, Deep Learning, Virtual Reality and Live Data Streaming"
						link="https://horus.nu/"
						city="Groningen"
						country="Netherlands"
					/>
					<PartnerCard
						name="TILab"
						color="#00ccff"
						image={data.logoTILab.childImageSharp.fluid}
						text="Aerial, Indoor and Street-level Mobile Mapping"
						link="http://tilab.co.kr/"
						city="Seoul"
						country="South Korea"
					/>
					<PartnerCard
						name="Van Oord"
						color="#253a79"
						image={data.logoVanOord.childImageSharp.fluid}
						text="We create a better world for future generations by delivering Marine ingenuity"
						link="https://www.vanoord.com/"
						city="Rotterdam"
						country="Netherlands"
					/>
					<PartnerCard
						name="Mølbak Landinspektører A/S"
						color="#457c49"
						image={data.logoMolbak.childImageSharp.fluid}
						text="One of Denmark's largest consulting land surveying companies"
						link="https://molbak.dk/"
						city="Roskilde"
						country="Denmark"
					/>
					<PartnerCard
						name="DigiTrans GmbH"
						color="#0099C4"
						image={data.logoDigitrans.childImageSharp.fluid}
						text="Bringing the future of autonomous transport to the road"
						link="https://digitrans.expert/"
						city="Linz"
						country="Austria"
					/>
					<PartnerCard
						name="Looom GmbH"
						color="#2e2870"
						image={data.logoLooom.childImageSharp.fluid}
						text="Solution provider for building digitalization"
						link="https://www.looom.at/"
						city="Ansfelden"
						country="Austria"
					/>
					<PartnerCard
						name="WiR Solutions GmbH"
						color="#e57d20"
						image={data.logoWIRSolutions.childImageSharp.fluid}
						text="Digitalization services"
						link="https://wir-solutions.de/"
						city="Greven"
						country="Germany"
					/>
					<PartnerCard
						name="Geograf Digital AS"
						color="#1C2258"
						image={data.logoGeografDigital.childImageSharp.fluid}
						text="Digital twins and surveying"
						link="https://geograf.no/"
						city="Stavanger"
						country="Norway"
					/>
					<PartnerCard
						name="D3E Electronique"
						color="#00a7d4"
						image={data.logoD3E.childImageSharp.fluid}
						text="Solutions for carto- and topographie"
						link="https://www.d3e.fr/"
						city="Troyes"
						country="France"
					/>
					<PartnerCard
						name="TPI"
						color="#003466"
						image={data.logoTPI.childImageSharp.fluid}
						text="Surveying equipment seller"
						link="https://www.tpi.com.pl/"
						city="Warsaw"
						country="Poland"
					/>
					<PartnerCard
						name="GEXCEL"
						color="#035399"
						image={data.logoGexcel.childImageSharp.fluid}
						text="Innovative surveying, LiDAR and monitoring solutions"
						link="https://gexcel.it/"
						city="Pula"
						country="Italy"
					/>
					<PartnerCard
						name="Geotronics Slovakia"
						color="#000000"
						image={data.logoGeotronicsSlovakia.childImageSharp.fluid}
						text="Surveying equipment seller"
						link="https://geotronics.sk/"
						city="Bratislava"
						country="Slovakia"
					/>
					<PartnerCard
						name="PLACE"
						color="#A020F0"
						image={data.logoPlace.childImageSharp.fluid}
						text="Hyperlocal Mapping for Positive Change"
						link="https://www.thisisplace.org/"
						city="Washington, DC"
						country="USA"
					/>					
				</div>
			</div>
		</section>
	</Layout>
)

export default Customers

export const logo = graphql`
	fragment logoCustomers on File {
		childImageSharp {
			fluid(maxHeight: 200) {
				...GatsbyImageSharpFluid
				...GatsbyImageSharpFluidLimitPresentationSize
			}
		}
	}
`

export const query = graphql`
	query {
		logoAllTerraDS: file(relativePath: { eq: "allterra-ds-logo.jpg" }) {
			...logoCustomers
		}
		logoStrabag: file(relativePath: { eq: "STRABAG_Logo_256h.png" }) {
			...logoCustomers
		}
		logoRedPlanet: file(relativePath: { eq: "redplanet-logo.png" }) {
			...logoCustomers
		}
		logoGpsLands: file(relativePath: { eq: "gps-lands-logo.png" }) {
			...logoCustomers
		}
		logoGalileoip: file(relativePath: { eq: "galileo-ip-logo.png" }) {
			...logoCustomers
		}
		logoComcentric: file(relativePath: { eq: "comcentric-logo.png" }) {
			...logoCustomers
		}
		logoConScanTech: file(relativePath: { eq: "conscantech-ifsb-logo.jpg" }) {
			...logoCustomers
		}
		logoAllTerraHungary: file(relativePath: { eq: "allterra-hu-logo.jpg" }) {
			...logoCustomers
		}
		logo3DTarget: file(relativePath: { eq: "3d-target-logo.png" }) {
			...logoCustomers
		}
		logoCamenAI: file(relativePath: { eq: "camenai-logo.png" }) {
			...logoCustomers
		}
		logoQapture: file(relativePath: { eq: "qapture.png" }) {
			...logoCustomers
		}
		logoOrbis360: file(relativePath: { eq: "orbis360-logo.png" }) {
			...logoCustomers
		}
		logoInfraDigital: file(relativePath: { eq: "infradigital-logo.png" }) {
			...logoCustomers
		}
		logoStarVision: file(relativePath: { eq: "starvision-logo.jpg" }) {
			...logoResellers
		}
		logoHorus: file(relativePath: { eq: "horus-logo.png" }) {
			...logoResellers
		}
		logoTILab: file(relativePath: { eq: "tilab-logo.png" }) {
			...logoResellers
		}
		logoTILab: file(relativePath: { eq: "tilab-logo.png" }) {
			...logoResellers
		}
		logoSTFGruppe: file(relativePath: { eq: "stf-gruppe-logo.png" }) {
			...logoCustomers
		}
		logoMolbak: file(relativePath: { eq: "molbak-logo.png" }) {
			...logoCustomers
		}
		logoVanOord: file(relativePath: { eq: "van-oord-logo.png" }) {
			...logoCustomers
		}
		logoDigitrans: file(relativePath: { eq: "digitrans-logo.png" }) {
			...logoCustomers
		}
		logoWIRSolutions: file(relativePath: { eq: "wir-logo.png" }) {
			...logoCustomers
		}
		logoGeografDigital: file(relativePath: { eq: "geograf-digital-logo.png" }) {
			...logoCustomers
		}
		logoD3E: file(relativePath: { eq: "d3e-logo.png" }) {
			...logoCustomers
		}
		logoTPI: file(relativePath: { eq: "tpi-logo.png" }) {
			...logoCustomers
		}
		logoGexcel: file(relativePath: { eq: "gexcel-logo.png" }) {
			...logoCustomers
		}
		logoGeotronicsSlovakia: file(relativePath: { eq: "geotronics-slovakia-logo.png" }) {
			...logoCustomers
		}
		logoNavVis: file(relativePath: { eq: "navvis-logo.png" }) {
			...logoCustomers
		}
		logoPlace: file(relativePath: { eq: "place-logo.png" }) {
			...logoCustomers
		}
		logoEsri: file(relativePath: { eq: "esri-startup-vertical.jpg" }) {
			...logoCustomers
		}
		logoInovitas: file(relativePath: { eq: "inovitas-logo.png" }) {
			...logoCustomers
		}
		logoLooom: file(relativePath: { eq: "looom-logo.png" }) {
			...logoCustomers
		}
	}
`
